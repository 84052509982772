require('./hoolah-library.css');
const config = require('./config');

const {
  minPrice,
  maxPrice,
  supportedCurrencies,
  collectionWidget,
  productWidget,

  hostName,
  merchantFolder,
  footerLogo,

  cssName
} = config;

(function() {
  var currencySymbolMap = {
    'SGD': '$',
    'MYR': 'RM',
    'THB': '฿',
    'EUR': '€',
    'HKD': '$'
  };

  // Include CSS file
  (function() {
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = [hostName, merchantFolder, `${cssName}.css`]
      .filter(segment => !!segment)
      .join('/');
    document.head.appendChild(link);
  })();

  function logoUrl(color) {
    return 'https://cdn.hoolah.co/images/hoolah-w100-' + color + '.png';
  }

  function compilePriceTemplate(template) {
    var keywords = ['symbol', 'currency', 'price'];
    var flags = keywords.map(function(keyword) { return '$' + keyword; });

    // A compiled template is represented as an array of objects (each with keys `type` and `value`).
    // type can be `variable` (which should be substituted) and `literal` (which should be used as is).
    function compileTemplate(template) {
      var output = [];

      while (template.length > 0) {
        var keywordsIndices = flags
          .map(function(flag) { return template.indexOf(flag); })
          .filter(function(id) { return id >= 0; })
          .sort();

        var firstKeywordIndex = keywordsIndices.length ? keywordsIndices[0] : template.length;

        if (firstKeywordIndex !== 0) {
          output.push({
            type: 'literal',
            value: template.substring(0, firstKeywordIndex)
          });
        }

        template = template.substring(firstKeywordIndex);

        var keywordIndex = flags.findIndex(function(flag) { return template.startsWith(flag) });

        if (keywordIndex === -1) {
          continue;
        }

        output.push({
          type: 'variable',
          value: keywords[keywordIndex]
        });

        template = template.substring(flags[keywordIndex].length);
      }

      return output;
    }

    var compiledTemplate = compileTemplate(template);

    return function(variables) {
      return compiledTemplate
        .map(function(instruction) {
          if (instruction.type === 'variable') {
            return variables[instruction.value];
          } else {
            return instruction.value;
          }
        })
        .join('');
    };
  }

  function templateGenerator(config) {
    var textStyle = '';
    if (!isNaN(config.fontSize)) {
      textStyle += 'font-size:' + config.fontSize + 'px;';
    }
    if (config.fontColor) {
      textStyle += 'color:' + config.fontColor + ';';
    }

    var logoStyle = 'width: auto; border-radius: 0;';
    if (!isNaN(config.logoHeight)) {
      logoStyle += 'height: ' + config.logoHeight + 'px;';
    }

    var priceTemplate = compilePriceTemplate(config.priceTemplate);

    function priceLabel(currency, symbol, price) {
      return priceTemplate({ currency: currency, symbol: symbol, price: price });
    }

    switch (config.theme) {
      case 1:
        return function(currency, symbol, price) {
          return '<span id="modal-elem" class="hoolah_text_1" style="' + textStyle + '">' +
            'or as low as ' +
            '<span class="bold">' + priceLabel(currency, symbol, price) + '</span>' +
            ' with ' +
            '<img src="' + config.logoUrl + '" style="' + logoStyle + '" />' +
            '</span>';
        };
      case 2:
        return function(currency, symbol, price) {
          return '<span id="modal-elem" class="hoolah_text_1" style="' + textStyle + '">' +
            'or ' +
            '<span class="bold">' + priceLabel(currency, symbol, price) + '</span>' +
            ' x 3 interest-free installments with ' +
            '<img src="' + config.logoUrl + '" style="' + logoStyle + '" />' +
            '</span>';
        };
      case 3:
        return function(currency, symbol, price) {
          return '<span id="modal-elem" class="hoolah_text_1" style="' + textStyle + '">' +
            'or 3 installments of ' +
            '<span class="bold">' + priceLabel(currency, symbol, price) + '</span>' +
            ' with ' +
            '<img src="' + config.logoUrl + '" style="' + logoStyle + '" />' +
            '</span>';
        };
      case 4:
        return function(currency, symbol, price) {
          return '<span id="modal-elem" class="hoolah_text_1" style="' + textStyle + '">' +
            'or as low as ' +
            '<span class="bold">' + priceLabel(currency, symbol, price) + '</span>' +
            ' x 3 0% installments with '+
            '<img src="' + config.logoUrl + '" style="' + logoStyle + '" />' +
            '</span>';
        };
      case 5:
        return function(currency, symbol, price) {
          return '<span id="modal-elem" class="hoolah_text_1" style="' + textStyle + '">' +
            'or ' +
            '<span class="bold">' + priceLabel(currency, symbol, price) + '</span>' +
            ' x 3 installments, 0% interest with ' +
            '<img src="' + config.logoUrl + '" style="' + logoStyle + '" />' +
            '</span>';
        };
      case 6:
        return function(currency, symbol, price) {
          return '<span id="modal-elem" class="hoolah_text_1" style="' + textStyle + '">' +
            'or 3 interest-free installments of ' +
            '<span class="bold">' + priceLabel(currency, symbol, price) + '</span>' +
            ' with ' +
            '<img src="' + config.logoUrl + '" style="' + logoStyle + '" />' +
            '</span>';
        };

      case 7:
        return function(currency, symbol, price) {
          return '<span id="modal-elem" class="hoolah_text_1" style="' + textStyle + '">' +
            'or 3x interest-free installments with ' +
            '<img src="' + config.logoUrl + '" style="' + logoStyle + '" />' +
            '</span>';
        };

      case 8:
        return function(currency, symbol, price) {
          return '<span id="modal-elem" class="hoolah_text_1" style="' + textStyle + '">' +
            'or as low as 3x ' +
            '<span class="bold">' + priceLabel(currency, symbol, price) + '</span>' +
            ' with ' +
            '<img src="' + config.logoUrl + '" style="' + logoStyle + '" />' +
            '</span>';
        };
      case 9:
        return function(currency, symbol, price) {
          return '<span id="modal-elem" class="hoolah_text_1" style="' + textStyle + '">' +
            'or ' +
            '<span class="bold">' + priceLabel(currency, symbol, price) + '</span>' +
            ' x 3 installments. No interest or fees with ' +
            '<img src="' + config.logoUrl + '" style="' + logoStyle + '" />' +
            '<span class="learn_more">Learn more</span>' +
            '</span>';

        };


    }
  }


  var modal, closeButton;
  function injectModal() {



  var container = document.createElement('div');
  container.innerHTML = '<div id="hoolah-modal" class="modal" style="max-width: 100%; margin-top: 0px">' +

// Modal wrapper content start
'<div class="hoolah_modal_wrapper"> <span class="hoolah_modal_close "></span> <div class="hoolah_modal_logo"> <a class="hoolah_logo" href="https://www.hoolah.co" target="_blank"><img src="https://cdn.hoolah.co/integration/hoolah-pd-modal/hoolah_red.png"></a> </div><div class="hoolah_modal_header"><h1 class="hoolah_font hoolah_h1">3 monthly interest-free installments</h1><p class="hoolah_font hoolah_p">Get what you need now, and pay later with hoolah. For more information, visit <a class="hoolah_font hoolah_a" href="https://hoolah.co/?ref=divider">hoolah.co</a></p></div><div class="hoolah_modal_section"> <div class="hoolah_modal_child"> <img src="https://cdn.hoolah.co/integration/hoolah-pd-modal/egg_icon.svg"> <h3 class="hoolah_font hoolah_h3">0% Interest<br>No processing fees</h3> <p class="hoolah_font hoolah_p">You only pay what you see.<br>There are no gimmicks.</p></div><div class="hoolah_modal_child"> <img src="https://cdn.hoolah.co/integration/hoolah-pd-modal/card_icon.svg"> <h3 class="hoolah_font hoolah_h3">Acceptance of both<br>debit and credit cards</h3> <p class="hoolah_font hoolah_p">Pay with any card type<br>issued by any banks.</p></div><div class="hoolah_modal_child"> <img src="https://cdn.hoolah.co/integration/hoolah-pd-modal/hare_icon.svg"> <h3 class="hoolah_font hoolah_h3">Seamless checkout</h3> <p class="hoolah_font hoolah_p">No credit checks. Just select hoolah as your payment option. </p></div></div><div class="hoolah_modal_button"><a class="hoolah_font hoolah_a" href="http://www.hoolah.co/#consumer_process" target="_blank">How it works?</a></div></div>' +
// Modal wrapper content end

    '</div>';
  document.body.appendChild(container);

  modal = document.getElementById('hoolah-modal');
  closeButton = container.querySelector('.hoolah_modal_close ');
}

  function showModal() {
    modal.style.display = "flex";
    modal.style.visibility = "visible";
    modal.style.opacity = "1";
    document.documentElement.classList.add('hoolah-body-modal');
  }

  function hideModal() {
    modal.style.display = "none";
    modal.style.visibility = "none";
    modal.style.opacity = "0";
    document.documentElement.classList.remove('hoolah-body-modal');
  }

  var productWidgetClass = 'hoolah_product_widget';
  var productWidgetSelector = '.' + productWidgetClass;
  var collectionWidgetClass = 'hoolah_collection_widget';
  var collectionWidgetSelector = '.' + collectionWidgetClass;


  function renderHoolahPriceWidget() {
    var productTemplate = templateGenerator({
      theme: productWidget.theme,
      logoUrl: logoUrl(productWidget.logoColor),
      fontSize: productWidget.fontSize,
      fontColor: productWidget.fontColor,
      logoHeight: productWidget.logoHeight,
      priceTemplate: productWidget.currencyFormat
    });

    var productWidgetsEnabled = productWidget.widgetEnabled;
    var productWidgets = document.getElementsByClassName(productWidgetClass);
    for (var i = 0; productWidgetsEnabled && i < productWidgets.length; i++) {
      var widget = productWidgets[i];

      var currency = (widget.getAttribute('data-currency') || '').toUpperCase();
      var currencySymbol = currencySymbolMap[currency];
      var price = parseFloat((widget.getAttribute('data-price') || '').replace(/[^0-9.]/g, ''));

      if (!currency || !currencySymbol) {
        continue;
      }

      if (supportedCurrencies.indexOf(currency) === -1) {
        continue;
      }

      if (isNaN(price)) {
        continue;
      }

      if (price < minPrice || price > maxPrice) {
        continue;
      }

      var installment = (price / 3).toFixed(2);

      var html = productTemplate(currency, currencySymbol, installment);

      widget.innerHTML = html;
    }

    var collectionTemplate = templateGenerator({
      theme: collectionWidget.theme,
      logoUrl: logoUrl(collectionWidget.logoColor),
      fontSize: collectionWidget.fontSize,
      fontColor: collectionWidget.fontColor,
      logoHeight: collectionWidget.logoHeight,
      priceTemplate: collectionWidget.currencyFormat
    });

    var collectionWidgetsEnabled = collectionWidget.widgetEnabled;
    var collectionWidgets = document.getElementsByClassName(collectionWidgetClass);
    for (var i = 0; collectionWidgetsEnabled && i < collectionWidgets.length; i++) {
      var widget = collectionWidgets[i];

      var currency = (widget.getAttribute('data-currency') || '').toUpperCase();
      var currencySymbol = currencySymbolMap[currency];
      var price = parseFloat((widget.getAttribute('data-price') || '').replace(/[^0-9.]/g, ''));

      if (!currency || !currencySymbol) {
        continue;
      }

      if (supportedCurrencies.indexOf(currency) === -1) {
        continue;
      }

      if (isNaN(price)) {
        continue;
      }

      if (price < minPrice || price > maxPrice) {
        continue;
      }

      var installment = (price / 3).toFixed(2);

      var html = collectionTemplate(currency, currencySymbol, installment);

      widget.innerHTML = html;
    }
  }

  // Test if an element matches a CSS selector
  function matchSelector(selector, elem) {
    var matches = elem.matches
      || elem.webkitMatchesSelector
      || elem.msMatchesSelector
      || elem.mozMatchesSelector
      || function (selector) {
        var matches = document.querySelectorAll(selector);

        for (var i = 0; i < matches.length; i++) {
          if (matches[i] === this) {
            return true;
          }
        }

        return false;
      };

    return matches.call(elem, selector);
  }

  function attachEventListeners() {

    closeButton.addEventListener('click', function(e) {
      hideModal();
    });

    modal.addEventListener('click', function(e) {
      if (e.target === modal) {
        hideModal();
      }
    });

    document.addEventListener('click', function(event) {
      var collectionWidgetLogo = collectionWidgetSelector + ' img';
      var productWidgetLogo = productWidgetSelector + ' img';

      var target = event.target;
      if (matchSelector(collectionWidgetLogo, target) && collectionWidget.logoLink) {
        showModal();
      } else if (matchSelector(productWidgetLogo, target) && productWidget.logoLink) {
        showModal();
      }
    });
  }

  function renderFooterLogo() {
    if (!footerLogo || !footerLogo.logoEnabled) {
      return;
    }

    const container = document.createElement(footerLogo.logoTag);

    const target = document.querySelector(footerLogo.logoInjection);

    if (!footerLogo.logoFile.toLowerCase().endsWith('svg')) {
      const logo = document.createElement('img');
      logo.src = `https://cdn.hoolah.co/images/${footerLogo.logoFile}`;
      if (footerLogo.logoCssClass) {
        logo.classList.add(footerLogo.logoCssClass);
      }
      if (footerLogo.logoCssInline) {
        logo.setAttribute('style', footerLogo.logoCssInline);
      }

      container.appendChild(logo);
      target.insertBefore(container, target.firstChild);
    } else {
      const logoLink = `https://cdn.hoolah.co/images/${footerLogo.logoFile}`;

      const r = new XMLHttpRequest();
      r.open('GET', logoLink, true);
      r.onreadystatechange = () => {
        if (r.readyState !== 4 || r.status !== 200) return;

        const data = r.responseText;

        const tmp = document.createElement('div');
        tmp.innerHTML = data;
        const svg = tmp.firstElementChild;

        container.appendChild(svg);
        target.insertBefore(container, target.firstChild);
      };
      r.send();
    };
  }

  document.addEventListener('DOMContentLoaded', function init() {
    injectModal();
    attachEventListeners();
    renderHoolahPriceWidget();
    renderFooterLogo();

    document.removeEventListener('DOMContentLoaded', init, false);
  }, false);
})();
